import { CamelcaseSerializer } from 'cerealizr'

import { api } from '../config/api'

const serializer = new CamelcaseSerializer()

const serializeUsersResponse = (response) => {
  const { count, page, total_pages: totalPages, users } = response.data
  return {
    data: {
      count,
      page,
      totalPages,
      users: users.map((obj) => serializer.serialize(obj))
    }
  }
}

// eslint-disable-next-line no-magic-numbers
export const getUsers = ({ sort, limit = 10, ...params }) => {
  const baseUrl =
    sort && sort.by && sort.order ? `user/distributors?order[${sort.by}]=${sort.order}` : 'user/distributors'
  return api.get(baseUrl, { params: { ...params, limit } }).then(serializeUsersResponse)
}

// CUIDADO, hay que cambiar la cantidad de límites para que sea dinámico

export const getUserDetails = (id) =>
  api.get(`user/distributor/${id}`).then((response) => serializer.serialize(response.data.user))

export const getUserDetailsWbV2DefaultUser = () =>
  api.get(`user/distributor_wbv2_default_user`).then((response) => serializer.serialize(response.data.user))

export const editUser = (id, user) => api.put(`user/distributor/${id}`, user)

export const toggleEnableUser = (id, enabled) => api.post(`user/distributor/${id}/enable`, enabled)

export const toggleAdvance = (id, enabled) => api.post(`/backoffice/advance_enabler/${id}`, enabled)

export const toggleMercadopago = (id, enabled) => api.put(`/backoffice/mp_enabler/${id}`, enabled)

export const toggleCheckPaymentEnabler = (id, enabled) => api.put(`/backoffice/check_enabler/${id}`, enabled)

export const toggleLogoFreeEnabler = (id, enabled) => api.put(`/backoffice/logo_free_enabler/${id}`, enabled)

export const toggleDashboardEnabler = (id, enabled) => api.put(`/backoffice/dashboard_enabler/${id}`, enabled)

export const toggleApiIntegrationEnabler = (id, enabled) => api.put(`/backoffice/api_integration_enabler/${id}`, enabled)

export const refreshApikey = (id) => api.put(`/backoffice/api_key_refresh/${id}`)

export const toggleWireTransfer = (id, enabled) => api.put(`/backoffice/wire_transfer_enabler/${id}`, enabled)

export const toggleEnableWhiteBox = (id) =>
  api.post(`/backoffice/white_box/${id}/enabler`).then((response) => serializer.serialize(response.data))

export const toggleEnableWBPrices = (id) =>
  api
    .post(`/backoffice/white_box/${id}/price_enabler`)
    .then((response) => serializer.serialize(response.data))

export const getProvinces = (body) => api.get('province', { params: body })

export const getLevels = () => api.get('/level')

export const getAdvances = (id) => api.get(`/backoffice/userAdvances/${id}`)
// TODO: Remove promise and get data from api when endpoint is ready
export const deleteManager = (id) => new Promise((resolve) => resolve({ data: id }))

// TODO: Remove promise and get data from api when endpoint is ready
export const getManagers = (filters) => new Promise((resolve) => resolve({ data: filters }))

export const editWhiteBox = (id, whitebox) =>
  api.put(`/backoffice/white_box/${id}`, whitebox).then((response) => serializer.serialize(response.data))

export const getWhiteBoxHostList = () => api.get('/backoffice/white_box/host/list')

export const toggleWhiteBoxV2 = (distributorUserId) =>
  api
    .post(`/backoffice/white_box_v2/toggle/${distributorUserId}`)
    .then((response) => serializer.serialize(response.data))

export const editWhiteBoxV2 = (distributorUserId, whitebox) =>
  api
    .put(`/backoffice/white_box_v2/edit/${distributorUserId}`, whitebox)
    .then((response) => serializer.serialize(response.data))

export const getPreOrders = (distributorUserId) =>
  api.get(`/backoffice/white_box_v2/pending_orders/${distributorUserId}`).then((response) => response)

export const sendActivationEmail = distributorUser =>
  api.post('/backoffice/white_box_v2/activation_email', distributorUser)

