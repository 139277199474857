export default {
  title: 'Información de Usuario',
  errors: {
    externalId: 'El id tiene que tener 6 caracteres',
    cuit: 'El RUT es inválido',
    formatEmail: 'Formato: prueba@email.com',
    formatUrl: 'Formato: https://sitioweb.com',
    maxLength: 'Este campo debe utilizar menos caracteres',
    required: 'Campo requerido',
    minValue: 'Debe ingresar un valor mayor o igual a {num}',
    email: 'Ingrese una direccion de email valida'
  },
  fields: {
    active: 'Activo',
    advance: 'Adelanto de entrega',
    altLogo: 'Logo del Partner',
    bejermanId: 'Id de Bejerman',
    businessName: 'Razón Social',
    credit: 'Crédito',
    cuit: 'RUT',
    currency: 'Pesos chilenos',
    email: 'E-mail',
    enabled: 'Habilitado',
    firstName: 'Nombre',
    incomesReportUrl: 'Comprobante IIBB',
    logoObservationsCount: 'Observaciones de logo',
    lastName: 'Apellido',
    level: 'Categoría',
    locality: 'Localidad',
    logoUrl: 'Logo',
    mercadopago: 'Mercado Pago',
    cheque: 'Cheque',
    wireTranfer: 'Transferencia',
    expressDeliveryCount: 'Muestra simple',
    name: 'Nombre de empresa',
    phoneNumber: 'Teléfono',
    points: 'Puntos',
    provinceId: 'Provincia',
    reservationLimit: 'Límite de reserva en pesos chilenos',
    customizationUnderMinimum: 'Personalización sin mínimos',
    customizationUnderMinTooltip:
      'Cantidad de veces que el partner puede comprar productos personalizados por debajo del mínimo',
    availableReserveAmount: 'Monto de reserva disponible',
    availableDiscountPercentage: 'Descuento por categoría',
    sellerName: 'Nombre de Persona',
    state: 'Estado',
    street: 'Calle',
    streetNumber: 'Número',
    userLogo: 'Logo del usuario',
    zipCode: 'Código Postal',
    pointsLimit: 'Este campo solo puede contener 9 digitos',
    readOnly: 'Este campo es de solo lectura',
    freeLogo: "Logo Gratis",
    dashboard: "Ver dashboard",
    titleGeneralSection: 'Habilitaciones Generales',
    titlePaymentsSection: 'Habilitaciones de Medios de Pagos',
    apiIntegrationStatus: 'Habilitar integración por API',
    integrationToken: 'Token de integración',
    integrationTokenBtn: 'Generar password'
  },
  states: {
    approved: 'Aceptado',
    denied: 'Deshabilitado',
    pendingDocumentation: 'Pendiente carga de documentos',
    pendingEmailValidation: 'Pendiente confirmación de email',
    pendingManualValidation: 'Pendiente validación manual'
  },
  altWhiteBoxLogo: 'Logo de caja blanca',
  attachBanner: 'Agregar banner',
  bannerRecommendations:
    'Se recomiendan imágenes horizontales, rectangulares, de máximo 250px de alto y 1200px de ancho',
  btnCancel: 'Cancelar',
  btnSave: 'Guardar',
  enableWhiteBox: 'Activar caja blanca',
  updateAllWhiteBoxes: 'Aplicar a todas las cajas blancas',
  invalidFields: 'Campos inválidos',
  placeholderLogo: 'URL del logo',
  primaryColor: 'Color primario',
  stateInfo: 'Estado: {state}',
  uploadWhiteBoxLogo: 'Subir logo',
  whiteBox: 'Configuración de caja blanca',
  whiteBoxConfig: 'Configuración de caja blanca',
  whiteBoxColorError: 'Debe seleccionar un color primario',
  whiteBoxColorTip: 'Utiliza preferentemente colores oscuros',
  whiteBoxEnableError: 'Debe guardar la caja blanca antes de habilitarla',
  whiteBoxEnableShowPrices: 'Precios visibles',
  whiteBoxLogo: 'Logo de la caja blanca',
  whiteBoxLogoError: 'Debe ingresar un logo de caja blanca',
  whiteBoxMaxBannersError: 'Debe subir un máximo de 5 banners',
  whiteBoxDesktopBannersError: 'Debe cargar como mínimo un banner de medida 1 para el Banner 1 y otro para el Banner 2.',
  whiteBoxMobileBannersError: 'Debe cargar como mínimo un banner de medida 3 para el Banner 1 y otro para el Banner 2.',
  whiteBoxBannersError: 'Se debe cargar como mínimo una medida de Desktop y una medida de Mobile tanto para BANNER 1 como para BANNER 2.',
  whiteBoxBannersDefaultMsg: 'Se cargaron los banners por default, por favor guarde para que se apliquen los cambios.',
  whiteBoxMinBannersError: 'Debe subir al menos 1 banner',
  whiteBoxEnablePrice: 'Porcentaje a aplicar',
  whiteBoxPriceHelp:
    'El porcentaje se va a aplicar sobre el precio de lista indistintamente del descuento del Partner',
  whiteBoxEnablePriceError: 'Debe ingresar un porcentaje a aplicar para la caja blanca',
  whiteBoxUrl: 'Url de la caja blanca',
  whiteBoxUrlError: 'Debe ingresar una url para la caja blanca',
  whiteBoxHostLabel: 'Dominio',
  whiteBoxV2: 'Caja blanca 2.0',
  configBanners: 'Configurar banners',
  bannersInfo: 'Debe subir {num} banners desktop y {num} banners mobile',
  bannersNum: 'Banner {num}',
  measureNum: 'Medida {num}',
  measure1: '1920 x 700 px -máximo 800kb',
  measure2: '1580 x 700 px -máximo 800kb',
  measure3: '400 x 600 px -máximo 400kb',
  measure4: '400 x 480 px -máximo 400kb',
  measure5: '400 x 300 px -máximo 400kb',
  minProfitPercentage: 'Porcentaje mínimo',
  maxProfitPercentage: 'Porcentaje máximo',
  resendActivation: 'Reenviar mail de activación',
  desktop: 'Desktop',
  mobile: 'Mobile',
  activationMailFail: 'Ocurrió un error al intentar enviar el Email de activación de Caja Blanca',
  activationMailSuccess: 'El email de activación de Caja Blanca fue enviado exitosamente',
  noWhiteBoxV2Url: 'Aún no hay una url configurada'
}
