import { setHeader, removeHeader, setCsvHeader, removeCsvHeader } from '@/config/api'
import router, { routes, menuLinks } from '@/config/routes'

import { login, getCurrentUserInfo, recoverPassword, resetPassword } from '@/services/AuthService'
import LocalStorageService from '@/services/LocalStorageService'

// TODO: delete these when get real info user from API
import image from '@/assets/image.png'

export default {
  namespaced: true,

  state: {
    loginLoading: false,
    loginError: null,
    currentUser: {},
    isLogged: false,
    recoverPasswordLoading: false,
    recoverPasswordError: null,
    resetPasswordLoading: false,
    resetPasswordError: null
  },

  mutations: {
    LOGIN(state, { data, redirectTo }) {
      state.currentUser = { ...data, name: data.email, image }
      state.loginLoading = false
      state.isLogged = true
      if (redirectTo && redirectTo.path) {
        router.push(redirectTo.path)
      } else {
        const { permissions } = data
        const links = menuLinks.map(link => ({
          ...link,
          canView: permissions.includes(link.meta.permission)
        }))
        const routeDefault = links.find(link => link.canView)
        router.push({ name: routeDefault?.name || routes.login })
      }
    },
    LOGIN_LOADING(state, status) {
      state.loginLoading = status
    },
    LOGIN_ERROR(state, error) {
      state.loginError = error
      state.loginLoading = false
    },
    RESET_ERROR(state) {
      state.loginError = null
    },
    LOGOUT(state) {
      LocalStorageService.removeSessionToken()
      removeHeader('Authorization')
      removeCsvHeader('Authorization')
      state.currentUser = null
      router.push({ name: routes.login })
      state.isLogged = false
    },
    SET_USER_DATA(state, data) {
      state.currentUser = { ...data, name: data.email, image }
    },
    RECOVER_PASSWORD_LOADING(state, status) {
      state.recoverPasswordLoading = status
    },
    RECOVER_PASSWORD_ERROR(state, error) {
      state.recoverPasswordError = error
      state.recoverPasswordLoading = false
    },
    RESET_RECOVER_PASSWORD_ERROR(state) {
      state.recoverPasswordError = null
    },
    RESET_PASSWORD_LOADING(state, status) {
      state.resetPasswordLoading = status
    },
    RESET_PASSWORD_ERROR(state, error) {
      state.resetPasswordError = error
      state.resetPasswordLoading = false
    },
    RESET_RESET_PASSWORD_ERROR(state) {
      state.resetPasswordError = null
    }
  },

  actions: {
    initUser({ commit, dispatch }, { token, redirectTo }) {
      LocalStorageService.setSessionToken(token)
      setHeader('Authorization', token)
      setCsvHeader('Authorization', token)
      dispatch('user/hydrateProvinces', null, { root: true })
      getCurrentUserInfo().then(({ data }) => commit('LOGIN', { data, redirectTo }))
    },
    login({ commit, dispatch }, body) {
      commit('LOGIN_LOADING', true)
      login(body).then(
        ({ data }) => dispatch('initUser', { token: data.authorization }),
        ({ response }) => commit('LOGIN_ERROR', response.data)
      )
    },
    isUserLoggedIn({ dispatch }, redirectTo) {
      const token = LocalStorageService.getSessionToken()
      if (token) dispatch('initUser', { token, redirectTo })
    },
    resetError({ commit }) {
      commit('RESET_ERROR')
    },
    logout({ commit }) {
      commit('LOGOUT')
    },
    updateUserInfo({ commit }) {
      getCurrentUserInfo().then(({ data }) => commit('SET_USER_DATA', data))
    },
    recoverPassword({ commit }, email) {
      commit('RECOVER_PASSWORD_LOADING', true)
      return recoverPassword(email).then(
        () => {
          commit('RECOVER_PASSWORD_LOADING', false)
          return Promise.resolve()
        },
        ({ response }) => {
          commit('RECOVER_PASSWORD_ERROR', response.data)
          return Promise.reject(response.data)
        }
      )
    },
    resetRecoverPasswordError({ commit }) {
      commit('RESET_RECOVER_PASSWORD_ERROR')
    },
    resetPassword({ commit }, { token, password }) {
      commit('RESET_PASSWORD_LOADING', true)
      return resetPassword(token, password).then(
        () => {
          commit('RESET_PASSWORD_LOADING', false)
          return Promise.resolve()
        },
        ({ response }) => {
          commit('RESET_PASSWORD_ERROR', response.data)
          return Promise.reject(response.data)
        }
      )
    },
    resetResetPasswordError({ commit }) {
      commit('RESET_RESET_PASSWORD_ERROR')
    }
  }
}
