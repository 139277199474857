export default {
  title: 'Listado de Productos',
  titleWepod: 'Listado de productos publicados en Wepod',
  packageDimensions: 'Dimensiones del empaque',
  productDimensions: 'Dimensiones del producto',
  generalConfigurations: 'Configuraciones generales',
  expressAvailableMsg:
    'El producto debe tener los valores de sus dimensiones para poder habilitar la muestra simple',
  fields: {
    printingProfileBase: 'Perfil de impresión para ',
    name: 'Nombre',
    description: 'Descripción',
    family: 'Categorías',
    mainFamily: 'Categoría principal',
    familyWepod: 'Categorías Wepod',
    featured: 'Oferta destacada',
    subfamily: 'Subcategoría',
    price: 'Precio (ARS)',
    priceZd: 'Precio Zecat',
    priceWepod: 'Precio Wepod ($)',
    published: 'Publicado',
    publishedWepod: 'Publicado en Wepod',
    publishedAt: 'Publicado en',
    height: 'Altura (cm)',
    length: 'Longitud (cm)',
    width: 'Ancho (cm)',
    deep: 'Profundidad (cm)',
    unitWeight: 'Peso x un.(grs)',
    unitsPerBox: 'Un. x caja',
    typeOfPackaging: 'Tipo de empaque',
    minimumOrderQuantity: 'Cantidad mínima',
    expressDeliveryCount: 'Muestra simple',
    minimumProfitPercentage: 'Porcentaje Mínimo de Ganancia',
    suggestedProfitPercentage: 'Porcentaje Sugerido de Ganancia',
    finalConsumerPriceWepod: 'Precio consumidor final',
    printingTypes: 'Tipo de impresión',
    productDescription: 'Descripción',
    tag: 'Tags',
    qrUrl: 'QR producto',
    tax: 'Impuestos',
    buyBySize: 'Compra por curva',
    wepod: 'Habilitar Wepod',
    opLog: 'Habilitar Op. Logístico',
    urlDownloadable: 'URL (link) material descargable',
    supplementaryInformation: 'Información complementaria'
  },
  publishedLabel: {
    active: 'Publicado',
    inactive: 'No publicado',
    all: 'Todos'
  },
  noResultMessage: 'No se encontraron productos',
  productDetails: 'Información del Producto',
  editText: 'Editar',
  cancelText: 'Cancelar',
  withoutData: {
    family: 'Sin categoría',
    subfamily: 'Sin subcategoría',
    publishedAt: 'Sin fecha de publicación',
    height: 'Sin altura',
    length: 'Sin longitud',
    unitWeight: 'Sin peso x unidad',
    unitsPerBox: 'Sin unidades x caja',
    tax: 'Sin impuesto'
  },
  uploadPrices: 'Actualizar precios',
  uploadPrintingTypes: 'Actualizar tipos de impresion',
  successPricesUpdate: 'Precios actualizados con exito',
  successArrivalStockUpdate: 'Stock en tránsito actualizado correctamente',
  successPrintingTypesUpdate: 'Tipos de impresion actualizados con exito',
  priceUpdateError: 'Hubo un error en la carga de csv, pruebe de nuevo más tarde',
  arrivalStockUpdateUpdateError: 'Hubo un error en la carga de csv, pruebe de nuevo más tarde',
  columnPrice: 'Precio',
  noProfileError: 'El perfil de impresión es obligatorio para el tipo de impresión {name}',
  columnPriceWepod: 'Precio Wepod'
}
