export default {
  welcome: 'Bienvenido a Zecat',
  login: 'Login',
  userLabel: 'Ingrese su usuario',
  username: 'Usuario',
  passwordLabel: 'Ingrese su contraseña',
  password: 'Contraseña',
  errorTitle: 'Error de credenciales',
  errorText: 'El usuario o la contraseña son incorrectas',
  inactiveUser: 'El usuario se encuentra deshabilitado. Comunicarse con un administrador',
  recoverPassword: {
    title: 'Recuperar contraseña',
    emailLabel: 'Email',
    emailPlaceholder: 'Email',
    backButton: 'Volver',
    submitButton: 'Enviar',
    invalidEmail: 'Por favor ingrese un email válido',
    successTitle: 'Email enviado',
    successMessage: 'Se ha enviado un email con las instrucciones para recuperar su contraseña',
    errorTitle: 'Error',
    errorMessage: 'No se pudo procesar la solicitud'
  },
  resetPassword: {
    title: 'Nueva contraseña',
    newPasswordLabel: 'Nueva contraseña',
    newPasswordPlaceholder: 'Nueva contraseña',
    confirmPasswordLabel: 'Repetir nueva contraseña',
    confirmPasswordPlaceholder: 'Repetir nueva contraseña',
    submitButton: 'Confirmar',
    passwordRules: {
      uppercase: 'Debe tener como mínimo 1 mayúscula',
      number: 'Debe tener como mínimo 1 número',
      length: 'Debe tener como mínimo 10 caracteres',
      different: 'No debe ser igual a la contraseña anterior'
    },
    passwordsNotMatch: 'Las contraseñas no coinciden',
    successTitle: 'Contraseña actualizada',
    successMessage: 'Su contraseña ha sido actualizada exitosamente',
    errorTitle: 'Error',
    resetError: 'No se pudo actualizar la contraseña'
  }
}
