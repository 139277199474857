import { api } from '../config/api'
import LocalStorageService from './LocalStorageService'

export const login = body => api.post('user/backoffice/login', body)

export const isLoggedIn = () => !!LocalStorageService.getSessionToken()

export const getCurrentUserInfo = () => api.get('user/backoffice/me')

export const recoverPassword = email => api.post('user/backoffice/password_recovery', { email })

export const resetPassword = (token, password) => api.post('user/backoffice/password_update', { token, password })
