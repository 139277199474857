export default {
  apartment: 'Departamento: {apartment}',
  apply: 'Aplicación',
  applicationPrice: 'Costo de aplicación: ',
  approveImage: 'Aprobar imagen',
  approvePayment: 'Aprobar pago',
  areaTitle: 'Area {num}',
  areaPantone: '(Pantone: {color} / {type} )',
  areaColorHex: '(Hexa: {color_hex} )',
  areaItem: 'Item {num}',
  areaItemHeight: 'Altura: {value} cm',
  areaItemFont: 'Fuente: {value}',
  areaItemSize: 'Tamaño: {value}',
  areaItemType: 'Tipo: {value}',
  areaItemWidth: 'Ancho: {value} cm',
  areaItemTypeNames: {
    texts: 'Texto',
    images: 'Imagen'
  },
  back: 'Volver',
  btnDownloadPreviews: 'Descargar las vistas previas',
  btnDownloadLogos: 'Descargar logos en curvas',
  cancelOrderAlert: '¿Está seguro de que quiere cancelar el pedido?',
  cancelOrder: 'Cancelar pedido',
  cancelSuccess: 'La orden fue cancelada con exito',
  cancelError: 'La orden no pudo ser cancelada',
  date: 'Fecha de creación: {date}',
  observationCategoryName: 'Categoría: {category}',
  observationText: 'Observaciones: {text}',
  observationCell: 'Celular: {text}',
  observationContactName: 'Contacto: {text}',
  deliveryComment: 'Comentario de envío: {deliveryComment}',
  deliveryDate: 'Fecha de entrega estimada : {date}',
  deliveryMethod: 'Forma de envío: {deliveryMethod}',
  downloadPDFProd: 'Descargar PDF para producción',
  erpError: 'Error de sincronización, intente nuevamente',
  erpSynched: 'Orden ya sincronizada',
  externalId: 'Id externo: {id}',
  kit: 'KIT: {kit}',
  deliveryMethods: {
    pickup: 'Forma de entrega: Retira por la sucursal',
    shipping: 'Forma de entrega: Entrega a domicilio'
  },
  direction: 'Dirección',
  id: 'Número de pedido: {id}',
  repeatPurchase: '#NP por repetición de compra: {id}',
  locality: 'Localidad: {locality}',
  openVoucher: 'Descargar comprobante de compra',
  openPaymentChangeVoucher: 'Descargar comprobante de cambio de pago',
  paymentMethod: 'Forma de pago: {paymentMethod}',
  paymentMethods: {
    check: 'Forma de pago: Transferencia Bancaria / Cheque',
    wire_transfer: 'Forma de pago: Transferencia Bancaria / Cheque',
    checking_account: 'Forma de pago: Crédito',
    mp_payment: 'Forma de pago: Mercado Pago'
  },
  productionBusinessDays: 'Días habiles: {days}',
  province: 'Provincia: {province}',
  quantity: 'Cantidad: {quantity} un.',
  sku: 'SKU: {sku}',
  state: 'Estado: {state}',
  states: {
    canceled: 'Cancelado',
    pending_payment: 'Pendiente de pago',
    pending_image_approval: 'Pendiente aprobación imagen',
    pending_payment_approval: 'Pendiente aprobación de pago',
    in_production: 'En producción',
    delivery: 'En envío',
    delivered: 'Entregado',
    payment_approved: 'Pago aprobado',
    image_approved: 'Imagen aprobada',
    picking: 'Retirando',
    pickup: 'Retira por la sucursal',
    ready_to_pickup: 'Listo para retirar',
    pending_delivery: 'Pendiente de envío',
    pending_pickup: 'Pendiente de retiro',
    returned_preparation: 'Devuelto a preparación',
    pending_prepared: 'Pendiente de preparación'
  },
  street: 'Calle: {street}',
  streetNumber: 'Número: {streetNumber}',
  subsidized: 'Descuento x Categoria',
  subtotal: 'Subotal',
  synchronize: 'Sincronizar con ERP',
  technique: 'Técnica: {technique}',
  titles: {
    deliveryData: 'Datos de Entrega',
    orderName: 'Nombre de la compra: {orderName}',
    orderDetails: 'Detalle de la orden',
    partnerData: 'Informacion de partner',
    printingAreas: 'Áreas de impresión',
    products: 'Productos',
    printConfig: 'Setup Máquina',
    observationsInfo: 'Observación Partner',
    advance: 'Adelanto',
    express: 'Muestra Simple',
    reservation: 'Reserva',
    repetition: 'Repetición',
    tuShop: 'Tu Shop'
  },
  titlePreview: 'Vista previa - {num}',
  total: 'Total',
  totalAclaration: 'El Total no incluye impuestos aproximados, costo de envío, PSM asi como tampoco tasa de interés por cheque',
  cuit: 'CUIT :',
  values: 'Valores',
  voucherUnavailable: 'Esta orden de compra no tiene comprobante asociado',
  withoutLogos: 'No se cargaron logos en curvas',
  zipCode: 'Código Postal: {zipCode}',
  notes: 'Observaciones: {notes}',
  logisticOperator: 'Operador logistico: {logisticOperator}',
  productName: 'Nombre: {name}',
  productVariant: 'Variante: {variant}',
  whiteBoxPreOrderId: 'ID Caja Blanca: {whiteBoxPreOrderId}',
  customMinExtra: 'Personalización debajo de los mínimos: ',
  inclination: 'Inc.: {value}°',
  downloadshippingGuidePDF: 'Descargar remito',
  downloadZippingTag: 'Descargar etiqueta zipping',
  quantityDiscount: 'Descuento x Cantidad',
  logoFreeDiscount: 'Descuento x Logo Gratis',
  psmExtra: 'PSM',
  repeatedOrderPurchase: 'Esta orden tuvo repetición de compra de la NP {numero}, está información es solamente aclaratoria ya que pudo ser modificada antes de realizar la compra'
}
